export function currentPeoplePrice(price, employees) {
  if (!price || !employees) return "00,00";
  const valorEmReais = price / 100;
  const totalPrice = valorEmReais * employees;
  const valorFormatado = totalPrice?.toFixed(2);
  const [parteInteira, parteDecimal] = valorFormatado?.split(".");
  const parteInteiraFormatada = parteInteira?.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );
  const valorFinalFormatado = `${parteInteiraFormatada},${parteDecimal}`;

  return valorFinalFormatado;
}
