import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 24px;
  gap: 24px;

  border: 1px solid #ebe6e9;
  border-radius: 8px;
  height: 100%;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export { StyledBox, StyledTitle, StyledText };
