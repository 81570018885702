import {
  ShapeIcon,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { CompanyInfo, Container, RightContainer } from "./styled";
import cnpj from "../../../../../utils/masks/cnpjMask";
import { CheckboxInternal } from "@components/Checkbox";

export const BoxAdornment = ({
  name,
  hasContract,
  registrationNumber,
  checked,
  setChecked,
}) => {
  return (
    <Container>
      <Tooltip
        placement="top"
        title={
          hasContract
            ? "Essa empresa já possui um contrato de Pessoas ativo, ou tem um contrato de Pessoas aguardando a assinatura. Para verificar, entre no perfil da empresa."
            : null
        }
      >
        <div>
          <CheckboxInternal
            disabled={hasContract}
            checked={hasContract || checked}
            onChange={setChecked}
          />
        </div>
      </Tooltip>

      <RightContainer>
        <ShapeIcon
          name="IconBuildingCommunity"
          variant="default"
          size={32}
          color="#F20D7A"
        />
        <CompanyInfo>
          <Typography variant="headline9" weight={700} variantColor="#FE2B8F">
            {name}
          </Typography>
          <Typography variant="caption" variantColor="#83727D">
            CNPJ: {cnpj(registrationNumber)}
          </Typography>
        </CompanyInfo>
      </RightContainer>
    </Container>
  );
};
