import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  AgreementContainer,
  Companies,
  Container,
  FooterContainer,
  HeaderContainer,
  StyledModal,
  Title,
} from "./styled";
import {
  Button,
  Checkbox,
  IconButton,
  Icons,
  LinkButton,
  SearchField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { SubsidiaryList } from "./SubsidiaryList";
import { Warning } from "./Warning";
import { CheckboxInternal } from "@components/Checkbox";

interface IModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  companyName: string | undefined;
  subsidiaryCompanies:
    | {
        id: string;
        legalName: string;
        registrationNumber: string;
        hasContract: boolean;
      }[]
    | undefined;
  termsChecked?: boolean;
  setTermsChecked?: Dispatch<React.SetStateAction<boolean>>;
  setSelectedSubsidiaryCompanies?: Dispatch<React.SetStateAction<string[]>>;
  selectedSubsidiaryCompanies?: string[];
  handleCreateSubscription?: () => void;
  loading: boolean;
  isEnabledOffers?: boolean | undefined;
}

export const SubsidiaryModal = ({
  open,
  setOpen,
  companyName,
  selectedSubsidiaryCompanies,
  subsidiaryCompanies,
  setSelectedSubsidiaryCompanies,
  termsChecked,
  setTermsChecked,
  handleCreateSubscription,
  loading,
  isEnabledOffers,
}: IModal) => {
  const [filteredCompanies, setFilteredCompanies] =
    useState(subsidiaryCompanies);

  useEffect(() => {
    setFilteredCompanies(subsidiaryCompanies);
  }, [subsidiaryCompanies]);

  const handleSearchChange = (value) => {
    setFilteredCompanies(
      subsidiaryCompanies?.filter((comp) =>
        comp?.legalName
          ?.toLocaleLowerCase()
          ?.includes(value?.toLocaleLowerCase())
      )
    );
  };

  const modalFooter = (
    <FooterContainer>
      <div>
        <LinkButton
          variant="secondary"
          onClick={() => {
            setOpen(false);
            setSelectedSubsidiaryCompanies?.([]);
            setTermsChecked?.(false);
            setFilteredCompanies(subsidiaryCompanies);
          }}
        >
          Cancelar
        </LinkButton>
      </div>
      <Button
        variant="primary"
        size="large"
        onClick={() => {
          handleCreateSubscription?.();
        }}
        loading={loading}
        disabled={!termsChecked}
      >
        Confirmar
        <Icons name={"IconCheck"} fill="transparent" color={"#FFF"} />
      </Button>
    </FooterContainer>
  );

  const modalHeader = (
    <HeaderContainer>
      <Title>
        <Typography variant="headline8" style={{ fontWeight: 700 }}>
          Escolher empresas
        </Typography>
        <Typography
          variant="body4"
          style={{ fontWeight: 400, color: "#6B5B66" }}
        >
          Selecione as empresas que ainda não possuem contrato de Pessoas. Para
          ajustar empresas que já fazem parte do contrato, entre em contato com
          o nosso suporte.
        </Typography>
      </Title>

      <IconButton
        icon="IconX"
        variant="line"
        size="small"
        onClick={() => {
          setOpen(false);
          setSelectedSubsidiaryCompanies?.([]);
          setTermsChecked?.(false);
          setFilteredCompanies(subsidiaryCompanies);
        }}
      />
    </HeaderContainer>
  );

  return (
    <StyledModal
      open={open}
      onClose={() => {
        setOpen(false);
        setSelectedSubsidiaryCompanies?.([]);
        setTermsChecked?.(false);
        setFilteredCompanies(subsidiaryCompanies);
      }}
      footer={modalFooter}
      header={modalHeader}
    >
      <Container>
        <Companies>
          <SearchField
            label="Buscar"
            onInputChange={(_, value) => {
              handleSearchChange(value);
            }}
          />
          <SubsidiaryList
            companyName={companyName}
            subsidiaryCompanies={filteredCompanies}
            selectedSubsidiaryCompanies={selectedSubsidiaryCompanies}
            setSelectedSubsidiaryCompanies={setSelectedSubsidiaryCompanies}
          />
        </Companies>
        <AgreementContainer>
          <CheckboxInternal
            onChange={() => setTermsChecked?.(!termsChecked)}
            checked={
              termsChecked ||
              subsidiaryCompanies?.every((company) => company?.hasContract)
            }
            disabled={
              (selectedSubsidiaryCompanies &&
                selectedSubsidiaryCompanies?.length <= 0) ||
              subsidiaryCompanies?.every((company) => company?.hasContract)
            }
          />
          <Typography variant="body3" weight={600} variantColor="#83727D">
            Concordo que o contrato desta empresa será replicado para as demais
            empresas selecionadas acima.
          </Typography>
        </AgreementContainer>
        <Warning isEnabledOffers={isEnabledOffers} />
      </Container>
    </StyledModal>
  );
};
