import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";
import pickBy from "lodash-es/pickBy";
import isNil from "lodash-es/isNil";
import isEmpty from "lodash-es/isEmpty";

import { trpc } from "../../../api/client";

export type Filter = {
  limit?: number;
  offset?: number;
  textSearch?: string;
  businessUnits?: string[];
  status?: string[];
  startDueDate?: string;
  endDueDate?: string;
};

type FilterConfig = {
  name: keyof Filter;
  type: StringConstructor | NumberConstructor | ArrayConstructor;
};

type Pagination = {
  pageIndex: number;
  pageSize: number;
};

const FILTER_CONFIG: FilterConfig[] = [
  { name: "limit", type: Number },
  { name: "offset", type: Number },
  { name: "textSearch", type: String },
  // { name: "businessUnits", type: Array },
  { name: "status", type: Array },
  { name: "startDueDate", type: String },
  { name: "endDueDate", type: String },
];

const ALLOWED_FILTERS = FILTER_CONFIG.map(({ name }) => name);

export const ARRAY_FILTERS = FILTER_CONFIG.filter(
  ({ type }) => type === Array
).map(({ name }) => name);

const DEFAULT_PAGINATION: Pagination = {
  pageIndex: 0,
  pageSize: 10,
};

const isValidFilter = (value, key) => {
  const isFilterAllowed = ALLOWED_FILTERS.includes(key);
  const isArray = ARRAY_FILTERS.includes(key);
  const isValidValue = isArray
    ? !isEmpty(value)
    : !isNil(value) && value !== "";
  return isFilterAllowed && isValidValue;
};

export const sanitizeFilter = (filter = {}) => pickBy(filter, isValidFilter);

const buildQuery = (filter = {}) =>
  new URLSearchParams(sanitizeFilter(filter)).toString();

const getBills = (companyId, filter = {}) => {
  const { data, isLoading, error } = trpc.getBills.useQuery(
    {
      companyId,
      query: buildQuery(filter),
    },
    { refetchOnWindowFocus: false, retry: false }
  );

  return {
    bills: data?.data || [],
    error,
    isLoading,
    meta: data?.meta,
  };
};

export default function useGetBills(initialFilter = {}) {
  const { selectedCompany } = useSelectedCompany();
  const [filter, setFilter] = useState<Filter>(initialFilter);
  const [pagination, setPagination] = useState<Pagination>(
    getInitialPagination(filter)
  );

  useEffect(() => {
    setFilter((previousFilter) => ({
      ...previousFilter,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
    }));
  }, [pagination]);

  const { bills, error, isLoading, meta } = getBills(
    selectedCompany.id,
    filter
  );

  return {
    bills,
    isLoading,
    error,
    pageCount: meta ? Math.ceil(meta.total / meta.limit) : 0,
    totalCount: meta?.total,
    filter,
    setFilter,
    pagination,
    setPagination,
  };
}

function getInitialPagination(filter: Filter) {
  const { limit, offset } = filter;
  const pageSize = limit ? +limit : DEFAULT_PAGINATION.pageSize;
  const pageIndex = offset ? offset / pageSize : DEFAULT_PAGINATION.pageIndex;
  return { pageSize, pageIndex } as Pagination;
}

export type UseGetBillsOutput = ReturnType<typeof useGetBills>;
