import { PaymentInfoBox } from "../../components/PaymentInfoBox";
import {
  Container,
  FlexColumnDiv,
  FlexDiv,
  FlexRowDiv,
  StyledPaymentMethodDescriptionText,
  StyledPaymentMethodInfos,
  StyledPaymentMethodTitle,
} from "./styled";

export const BilletBox = () => {
  const infos = {
    paymentMethod: "Boleto Bancário",
    paymentMethodDesciption:
      "O boleto será enviado para o e-mail <b>do financeiro</b> na data de cobrança.",
  };

  return (
    <>
      <Container>
        <FlexDiv>
          <StyledPaymentMethodTitle
            variant="headline8"
            style={{ fontWeight: 700 }}
          >
            {infos.paymentMethod}
          </StyledPaymentMethodTitle>
          <StyledPaymentMethodDescriptionText variant="body3">
            <div
              dangerouslySetInnerHTML={{
                __html: infos.paymentMethodDesciption,
              }}
            />
          </StyledPaymentMethodDescriptionText>
        </FlexDiv>

        <FlexRowDiv>
          <FlexColumnDiv>
            <StyledPaymentMethodDescriptionText
              variant="body4"
              style={{ fontWeight: 700 }}
            >
              Data de emissão
            </StyledPaymentMethodDescriptionText>
            <StyledPaymentMethodInfos
              variant="body3"
              style={{ fontWeight: 600 }}
            >
              01 de todo mês
            </StyledPaymentMethodInfos>
          </FlexColumnDiv>
          <FlexColumnDiv>
            <StyledPaymentMethodDescriptionText
              variant="body4"
              style={{ fontWeight: 700 }}
            >
              Vencimento do boleto
            </StyledPaymentMethodDescriptionText>
            <StyledPaymentMethodInfos
              variant="body3"
              style={{ fontWeight: 600 }}
            >
              20 de todo mês
            </StyledPaymentMethodInfos>
          </FlexColumnDiv>
        </FlexRowDiv>
        <PaymentInfoBox />
      </Container>
    </>
  );
};
