import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import styled from "styled-components";

export const DataInput = styled(TextField)`
  width: 100%;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
  margin-bottom: 8px;
`;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 32px;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  width: 100%;
  justify-content: center;
`;

export const GridBillingDataForm = styled(Grid)`
  margin: 40px 0px 24px 0px;
  width: 100%;
`;
