import { Dispatch } from "react";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledBox, StyledTitle } from "./styled";
import { segment } from "@utils";

export const SubsidiaryManager = ({
  setOpenModal,
}: {
  setOpenModal: Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <StyledBox>
      <div>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginBottom: "4px" }}
        >
          Empresas contempladas
        </StyledTitle>
      </div>
      <LinkButton
        variant="primary"
        style={{ alignSelf: "flex-start" }}
        onClick={() => {
          segment({
            track: "people_billing_subsidiaries_edit_clicked",
          });
          setOpenModal(true);
        }}
      >
        Editar
      </LinkButton>
    </StyledBox>
  );
};
