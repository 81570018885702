import { useNavigate } from "react-router-dom";
import { LinkButton, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledBox, StyledText, StyledTitle } from "./styled";

export const ChangePaymentMethod = ({
  paymentMethod,
}: {
  paymentMethod: string | undefined;
}) => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <div>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginBottom: "4px" }}
        >
          Meio de pagamento atual
        </StyledTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          {paymentMethod && (
            <>
              <ShapeIcon
                name={
                  paymentMethod == "Cartão de crédito"
                    ? "IconCreditCard"
                    : "IconBarcode"
                }
                variant="default"
                size={24}
                color="#F20D7A"
              />
              <StyledText variant="body3" style={{ fontWeight: 600 }}>
                {paymentMethod == "Cartão de crédito"
                  ? "Cartão de crédito"
                  : "Boleto bancário"}
              </StyledText>
            </>
          )}
        </div>
      </div>

      <LinkButton
        variant="primary"
        style={{ alignSelf: "flex-start" }}
        onClick={() => {
          navigate(`/billing/payment-method`);
        }}
      >
        Alterar
      </LinkButton>
    </StyledBox>
  );
};
