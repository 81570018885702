import { useNavigate } from "react-router-dom";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledBox, StyledText, StyledTitle } from "./styled";

export const PricePerUser = ({ value }: { value?: string }) => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <div>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginBottom: "4px" }}
        >
          Plano atual
        </StyledTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          {value && (
            <>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                {value}
              </StyledTitle>
              <StyledText variant="body3">por pessoa</StyledText>
            </>
          )}
        </div>
      </div>

      <LinkButton
        variant="primary"
        style={{ alignSelf: "flex-start" }}
        onClick={() => {
          navigate(`/contract-manager/plan`);
        }}
      >
        Detalhes do plano
      </LinkButton>
    </StyledBox>
  );
};
