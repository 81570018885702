import React, { useEffect, useImperativeHandle, useState } from "react";
import { getCep } from "@flash-tecnologia/hros-web-utility";
import {
  Container,
  DataInput,
  GridBillingDataForm,
  StyledSubtitle,
  StyledTitle,
} from "./styled";
import * as yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export type FormDataHandle = {
  handleSubmit: () => void;
  resetForm: () => void;
};

interface CreditCardFormProps {
  onSubmit: ({
    name,
    documentNumber,
    cardNumber,
    dueDate,
    cvv,
    street,
    complement,
    zipCode,
    state,
    district,
    city,
    number,
    phone,
  }: {
    name: string;
    documentNumber: string;
    cardNumber: string;
    dueDate: string;
    cvv: string;
    street: string;
    complement: string;
    zipCode: string;
    state: string;
    district: string;
    city: string;
    number: string;
    phone: string;
  }) => void;
}

export const CreditCardForm = React.forwardRef<
  FormDataHandle,
  CreditCardFormProps
>(({ onSubmit }, ref) => {
  const [handleInvalidZipCode, setHandleInvalidZipCode] =
    useState<boolean>(true);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Confira o nome e sobrenome no cartão")
      .max(64, "O número máximo de caracteres é 64"),
    cardNumber: yup
      .string()
      .min(16, "O Número do cartão deve conter no mínimo 13 dígitos")
      .max(23, "O Número do cartão deve conter no máximo 19 dígitos")
      .required("Confira o número do cartão"),
    dueDate: yup
      .string()
      .required("Confira a data de vencimento")
      .min(5, "A Data de vencimento deve conter 4 dígitos"),
    cvv: yup
      .string()
      .min(3, "O CVV deve conter no mínimo 3 dígitos")
      .max(4, "O CVV deve conter no máximo 4 dígitos")
      .required("Confira o CVV do cartão"),
    documentNumber: yup
      .string()
      .min(14, "O número deve conter contem 11 digitos")
      .max(14, "O número deve conter contem 11 digitos")
      .required("Por favor, digite o número do CPF"),
    phone: yup
      .string()
      .min(16, "O número deve conter contem 11 digitos")
      .max(16, "O número deve conter contem 11 digitos")
      .required("Por favor, digite o número de celular"),
    zipCode: yup
      .string()
      .min(9, "O CEP deve conter 8 digitos")
      .required("Por favor, digite o CEP da empresa"),
    street: yup.string().required("Por favor, digite o Logradouro da empresa"),
    number: yup.string().required("Por favor, digite o Número"),
    complement: yup.string().optional(),
    district: yup.string().required("Por favor, digite a Cidade da empresa"),
    state: yup.string().required("Por favor, digite o Estado da empresa"),
    city: yup.string().required("Por favor, digite o Cidade da empresa"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      documentNumber: "",
      cardNumber: "",
      dueDate: "",
      cvv: "",
      phone: "",
      zipCode: "",
      street: "",
      number: "",
      complement: "",
      district: "",
      state: "",
      city: "",
    },
    validationSchema: validationSchema,
    validate: () => {
      const errors: any = {};
      return errors;
    },
    onSubmit: ({
      name,
      dueDate,
      cvv,
      cardNumber,
      documentNumber,
      street,
      complement,
      zipCode,
      state,
      district,
      city,
      number,
      phone,
    }) => {
      const body = {
        name,
        dueDate,
        cvv,
        cardNumber,
        documentNumber,
        street,
        complement,
        zipCode,
        state,
        district,
        city,
        number,
        phone,
      };
      onSubmit(body);
    },
  });

  const creditCardFields = [
    {
      id: "name",
      name: "name",
      label: "Nome do titular",
      grid: 12,
      error: formik.touched.name && Boolean(formik.errors.name),
      helperText: formik.touched.name && formik.errors.name,
    },
    {
      id: "documentNumber",
      name: "documentNumber",
      label: "CPF",
      grid: 12,
      error:
        formik.touched.documentNumber && Boolean(formik.errors.documentNumber),
      helperText: formik.touched.documentNumber && formik.errors.documentNumber,
      mask: "000.000.000-00",
    },
    {
      id: "cardNumber",
      name: "cardNumber",
      label: "Número do Cartão",
      grid: 12,
      error: formik.touched.cardNumber && Boolean(formik.errors.cardNumber),
      helperText: formik.touched.cardNumber && formik.errors.cardNumber,
      mask: "0000 0000 0000 0000 000",
    },
    {
      id: "dueDate",
      name: "dueDate",
      label: "Data de vencimento",
      grid: 8,
      error: formik.touched.dueDate && Boolean(formik.errors.dueDate),
      helperText: formik.touched.dueDate && formik.errors.dueDate,
      mask: "00/00",
    },
    {
      id: "cvv",
      name: "cvv",
      label: "CVV",
      grid: 4,
      error: formik.touched.cvv && Boolean(formik.errors.cvv),
      helperText: formik.touched.cvv && formik.errors.cvv,
      mask: "0000",
    },
  ];
  const billingDataFields = [
    {
      id: "phone",
      name: "phone",
      label: "Celular",
      grid: 12,
      error: formik.touched.phone && Boolean(formik.errors.phone),
      helperText: formik.touched.phone && formik.errors.phone,
      mask: "(00) 0 0000-0000",
    },
    {
      id: "zipCode",
      name: "zipCode",
      label: "CEP",
      grid: 12,
      error:
        (formik.touched.zipCode && Boolean(formik.errors.zipCode)) ||
        !handleInvalidZipCode,
      helperText: formik.touched.zipCode && formik.errors.zipCode,
      mask: "00000-000",
    },
    {
      id: "street",
      name: "street",
      label: "Rua",
      grid: 8,
      error: formik.touched.street && Boolean(formik.errors.street),
      helperText: formik.touched.street && formik.errors.street,
    },
    {
      id: "number",
      name: "number",
      label: "Número",
      grid: 4,
      error: formik.touched.number && Boolean(formik.errors.number),
      helperText: formik.touched.number && formik.errors.number,
    },
    {
      id: "complement",
      name: "complement",
      label: "Complemento",
      grid: 12,
      error: formik.touched.complement && Boolean(formik.errors.complement),
      helperText: formik.touched.complement && formik.errors.complement,
    },
    {
      id: "district",
      name: "district",
      label: "Bairro",
      grid: 12,
      error: formik.touched.district && Boolean(formik.errors.district),
      helperText: formik.touched.district && formik.errors.district,
    },
    {
      id: "state",
      name: "state",
      label: "Estado",
      grid: 6,
      error: formik.touched.state && Boolean(formik.errors.state),
      helperText: formik.touched.state && formik.errors.state,
      mask: "aa",
    },
    {
      id: "city",
      name: "city",
      label: "Cidade",
      grid: 6,
      error: formik.touched.city && Boolean(formik.errors.city),
      helperText: formik.touched.city && formik.errors.city,
    },
  ];

  useEffect(() => {
    const parsedZipCode = formik?.values?.zipCode?.replace(/[^\d]+/g, "");

    if (parsedZipCode?.length === 8) {
      (async () => {
        const zipCodeData = await getCep(parsedZipCode);
        setHandleInvalidZipCode(!!zipCodeData.uf);
        formik.setValues({
          name: formik.values.name || "",
          dueDate: formik.values.dueDate || "",
          cvv: formik.values.cvv || "",
          cardNumber: formik.values.cardNumber || "",
          documentNumber: formik.values.documentNumber || "",
          phone: formik.values.phone || "",
          zipCode: formik.values.zipCode || "",
          street: zipCodeData.logradouro || "",
          number: formik.values.number || "",
          complement: formik.values.complement || "",
          district: zipCodeData.bairro || "",
          state: zipCodeData.uf || "",
          city: zipCodeData.localidade || "",
        });
      })();
    }

    formik.setValues({
      name: formik.values.name || "",
      dueDate: formik.values.dueDate || "",
      cvv: formik.values.cvv || "",
      cardNumber: formik.values.cardNumber || "",
      documentNumber: formik.values.documentNumber || "",
      phone: formik.values.phone || "",
      zipCode: formik.values.zipCode || "",
      street: formik.values.street || "",
      number: formik.values.number || "",
      complement: formik.values.complement || "",
      district: formik.values.district || "",
      state: formik.values.state || "",
      city: formik.values.city || "",
    });
  }, [formik.values.zipCode]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => formik.handleSubmit(),
    resetForm: () => formik.resetForm(),
  }));

  return (
    <Container>
      <Grid container md={12} spacing={3}>
        <div style={{ paddingLeft: "24px", paddingTop: "24px" }}>
          <StyledTitle variant="headline8">Cartão de crédito</StyledTitle>
          <StyledSubtitle variant="body3">
            A fatura será enviada para o e-mail{" "}
            <span style={{ fontWeight: 700 }}>do financeiro</span> na data da
            cobrança.
          </StyledSubtitle>
        </div>
        {creditCardFields.map((field) => {
          return (
            <>
              <Grid item md={field.grid}>
                <DataInput
                  id={field.id}
                  name={field.name}
                  label={field.label}
                  value={formik.values[field.id]}
                  error={field.error}
                  onChange={formik.handleChange}
                  helperText={field.helperText}
                  imaskProps={field.mask ? { mask: field.mask } : undefined}
                />
              </Grid>
            </>
          );
        })}
      </Grid>

      <GridBillingDataForm container xs={12}>
        <Typography
          variant="headline8"
          weight={700}
          style={{ marginBottom: "24px" }}
        >
          Dados de cobrança
        </Typography>
        <Grid container md={12} spacing={3}>
          {billingDataFields.map((field) => {
            return (
              <>
                <Grid item md={field.grid}>
                  <DataInput
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.id]}
                    error={field.error}
                    onChange={formik.handleChange}
                    helperText={field.helperText}
                    imaskProps={field.mask ? { mask: field.mask } : undefined}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </GridBillingDataForm>
    </Container>
  );
});
