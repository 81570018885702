import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Authentication } from "@flash-hros/auth-helper";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { FooterBar } from "../../components/FooterBar";
import { CreditCardForm } from "./components/CreditCardForm";
import { PaymentMethodBox } from "@components/PaymentMethodBox";
import { paymentMethods } from "../../mocks";
import { BilletBox } from "@components/PaymentMethodDescriptionBox";
import { SuccessModal } from "@components/SuccessModal";
import { Grid } from "@mui/material";
import { StyledSecondary, StyledText, StyledTitle } from "./styled";
import { dispatchToast, segment } from "../../utils";
import { trpc } from "../../api/client";

const { getAccessToken } = Authentication;

const CreditCardPage = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("creditCard");

  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const cardInfosRef = useRef<any>(null);
  const refToTop = useRef<any>(null);

  const { data, isLoading } = trpc.getContractByCompany.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const setupPaymentMethod = data?.contractData?.paymentMethod;

  useEffect(() => {
    refToTop.current.scrollIntoView({ behavior: "auto" });
  }, []);

  useEffect(() => {
    if (setupPaymentMethod == "Cartão de crédito") {
      setPaymentMethod("creditCard");
    } else {
      setPaymentMethod("billet");
    }
  }, [data]);

  const updateContractSetup = trpc.updateContractSetup.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const createCreditCard = trpc.createCreditCard.useMutation({
    onSuccess: () => {
      updateContractSetup.mutate({
        finance: { paymentMethodSelected: "creditCard" },
      });
    },

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const loading =
    createCreditCard.isLoading || updateContractSetup.isLoading || isLoading;

  const handleSubmitCreditCard = useCallback(
    async (props: {
      name: string;
      documentNumber: string;
      cardNumber: string;
      dueDate: string;
      cvv: string;
      street: string;
      complement: string;
      zipCode: string;
      state: string;
      district: string;
      city: string;
      number: string;
      phone: string;
    }) => {
      const date = props?.dueDate?.split("/");

      createCreditCard.mutate({
        billingAddress: {
          zipCode: props?.zipCode?.replace(/[^\d]+/g, ""),
          state: props?.state,
          number: parseInt(props?.number, 10) || 0,
          city: props?.city,
          street: props?.street,
          neighborhood: props?.district,
        },
        card: {
          number: props?.cardNumber?.replace(/\s+/g, ""),
          holder_name: props?.name,
          exp_month: date?.[0],
          exp_year: date?.[1],
          cvv: props?.cvv,
        },
        phone: {
          number: `${props?.phone.split(/[()]/)[2].replace(/[^\d]+/g, "")}`,
          areaCode: `${props?.phone?.split(/[()]/)[1]}`,
          countryCode: "55",
        },
      });
    },
    [setPaymentMethod]
  );

  return (
    <>
      <PageHeader style={{ margin: "0px" }}>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => {
                  navigate("/billing", { state: { tab: 2 } });
                },
                label: "Financeiro",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Alterar cartão",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid
        container
        spacing={3}
        style={{ padding: "0px 32px" }}
        ref={refToTop}
      >
        <Grid item md={12}>
          <StyledTitle variant="headline6">
            Alterar método de pagamento
          </StyledTitle>
        </Grid>
        <Grid item md={4}>
          <StyledSecondary variant="headline7" style={{ marginBottom: "8px" }}>
            Confira os detalhes da sua fatura
          </StyledSecondary>
          <StyledText variant="body3">
            Sua fatura será debitada todo dia 5. Eventuais cobranças pendentes
            serão processadas simultâneamente em até 3 horas.
          </StyledText>
        </Grid>
        <Grid item md={6}>
          <>
            <StyledTitle variant="headline7" style={{ fontWeight: 700 }}>
              Escolha seu método de pagamento
            </StyledTitle>
            <StyledText variant="body3" style={{ fontWeight: 400 }}>
              Escolha qual meio de pagamento faz mais sentido para você.
            </StyledText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                margin: "40px 0px",
              }}
            >
              {paymentMethods.map((payment, index) => {
                return (
                  <React.Fragment key={index}>
                    <PaymentMethodBox
                      paymentMethod={payment.paymentMethodLabel}
                      paymentMethodDescription={
                        payment.paymentMethodDescription
                      }
                      onPaymentMethodSelect={() => {
                        setPaymentMethod(payment.paymentMethod);
                        segment({ track: payment.segment });
                      }}
                      iconName={payment.icon}
                      checked={paymentMethod === payment.paymentMethod}
                    />
                  </React.Fragment>
                );
              })}
            </div>

            {paymentMethod == "creditCard" && (
              <CreditCardForm
                ref={cardInfosRef}
                onSubmit={handleSubmitCreditCard}
              />
            )}
            {paymentMethod == "billet" && <BilletBox />}
          </>
        </Grid>
      </Grid>

      <SuccessModal
        paymentMethod={paymentMethod}
        title={"Alteração do método de pagamento realizada!"}
        onOpen={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        route={"/billing"}
      />

      <FooterBar
        disabled={true}
        loading={loading}
        nextHandleClick={() => {
          switch (true) {
            case paymentMethod == "creditCard":
              cardInfosRef.current.handleSubmit();
              break;
            case paymentMethod == "billet":
              updateContractSetup.mutate({
                finance: { paymentMethodSelected: "billet" },
              });
              break;
          }
          segment({
            track:
              "company_configurations_finance_paymentsandbilling_confirm_button_clicked",
          });
        }}
        nextHandleButtonLabel={"Confirmar"}
        nextButtonIcon={"IconCheck"}
        previousHandleClick={() => {
          navigate("/billing", { state: { tab: 2 } });
          segment({
            track:
              "company_configurations_finance_paymentsandbilling_cancel_button_clicked",
          });
        }}
        previousHandleButtonLabel={"Cancelar"}
      />
    </>
  );
};

export { CreditCardPage };
