import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

export const Warning = ({
  isEnabledOffers,
}: {
  isEnabledOffers?: boolean | undefined;
}) => {
  return (
    <Container>
      <Icons size={24} name="IconInfoCircle" color="#015FCB" />
      {isEnabledOffers ? (
        <Typography variant="body4" variantColor="#00244C">
          Conclua a assinatura do novo contrato acessando a área de{" "}
          <strong style={{ fontWeight: 700 }}>Tarefas/Pendências</strong> na
          Home das filiais.
        </Typography>
      ) : (
        <Typography variant="body4" variantColor="#00244C">
          Conclua a assinatura do novo contrato acessando um dos{" "}
          <strong style={{ fontWeight: 700 }}>menus de Pessoas</strong> no
          perfil das filiais.
        </Typography>
      )}
    </Container>
  );
};
