import { useState, useEffect } from "react";
import { Grid, Skeleton } from "@mui/material";
import { SubscriptionDetailsBox } from "src/pages/BillingPagePeople/components/SubscriptionDetailsBox";
import { trpc } from "../../api/client";
import {
  CheckUnleashPermission,
  currentPeoplePrice,
  dispatchToast,
  getMonthEndDate,
  getMonthStartDate,
  segment,
  segmentPageTrack,
} from "@utils";
import { Bills } from "./components/BillsTable";
import { SubsidiaryModal } from "./components/SubsidiaryModal";
import {
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { ChangePaymentMethod } from "./components/ChangePaymentMethod";
import { SubsidiaryManager } from "./components/SubsidiaryManager";
import { PricePerUser } from "./components/PricePerUser";

const BillingPagePeople = () => {
  const [openSubsidiaryModal, setOpenSubsidiaryModal] =
    useState<boolean>(false);
  const [selectedSubsidiaryCompanies, setSelectedSubsidiaryCompanies] =
    useState<string[]>([]);
  const [acceptSubsidiaryTerms, setAcceptSubsidiaryTerms] =
    useState<boolean>(false);

  const { selectedCompany } = useSelectedCompany();
  const { economicGroupId } = getAccessTokenPayloadSync();

  const isEnabledOffers = CheckUnleashPermission({
    economicGroupId,
    flagName: "FLASH_OS_OFFERS_RECOMMENDATION_SECTION",
  });

  const { data, isLoading, isError } = trpc.getContractByCompany.useQuery(
    undefined,
    { refetchOnWindowFocus: false, retry: false }
  );

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    isError: isErrorCustomer,
  } = trpc.findCustomer.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: activeLivesBilled,
    isLoading: isLoadingActiveLivesBilled,
    isError: isErrorActiveLivesBilled,
  } = trpc.getActiveLivesBilled.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: subsidiaryCompanies,
    isError: isErrorCompanies,
    isLoading: isLoadingCompanies,
  } = trpc.getCompanyAndContract.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isErrorActiveLivesBilled) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar as vidas ativas. Tente novamente mais tarde",
    });
  }

  if (isErrorCompanies) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar as empresas do grupo. Tente novamente mais tarde",
    });
  }

  if (isError) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar o contrato da empresa. Tente novamente mais tarde",
    });
  }

  if (isErrorCustomer) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar os dados da empresa. Tente novamente mais tarde",
    });
  }

  const contractStatus = data?.contractData?.contract?.status;
  const price = data?.contractData?.price;
  const paymentMethod = data?.contractData?.paymentMethod;
  const companyType = data?.companyType;
  const priceNotFixed = data?.priceNotFixed;
  const subsidiaryRevenue = data?.subsidiaryRevenue;

  const createSubsidiaryContracts = trpc.createSubsidiaryContract.useMutation({
    onSuccess: async () => {
      setSelectedSubsidiaryCompanies([]);
      setAcceptSubsidiaryTerms(false);
      setOpenSubsidiaryModal(false);
      dispatchToast({
        content: "Empresas liberadas.",
        type: "success",
      });
    },
    onError: () =>
      dispatchToast({
        content:
          "Sentimos muito, ocorreu um erro ao liberar as filiais. Tente novamente mais tarde.",
        type: "error",
      }),
  });

  const loading =
    isLoading ||
    isLoadingCustomer ||
    isLoadingActiveLivesBilled ||
    isLoadingCompanies;

  useEffect(() => {
    console.log("people_billing_page_view", "page track");
    segmentPageTrack({
      name: "people_billing_page_view",
    });
  }, []);

  useEffect(() => {
    if (acceptSubsidiaryTerms) {
      segment({ track: "people_billing_subsidiaries_checkbox_clicked" });
    }
  }, [acceptSubsidiaryTerms]);

  const handleCreateSubscription = () => {
    if (!!selectedSubsidiaryCompanies?.length && acceptSubsidiaryTerms) {
      createSubsidiaryContracts.mutate({
        subsidiaryCompanies: selectedSubsidiaryCompanies,
        hasActiveOnUnleash: isEnabledOffers,
      });
    } else if (
      !!selectedSubsidiaryCompanies?.length &&
      !acceptSubsidiaryTerms
    ) {
      dispatchToast({
        type: "error",
        content: "É necessário aceitar os termos para prosseguir.",
      });
    } else if (!selectedSubsidiaryCompanies?.length && acceptSubsidiaryTerms) {
      dispatchToast({
        type: "error",
        content: "Selecione alguma empresa para prosseguir.",
      });
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : (
            <SubscriptionDetailsBox
              type={contractStatus != "CANCELLED" ? "open" : "canceled"}
              employees={activeLivesBilled || 0}
              price={currentPeoplePrice(priceNotFixed, activeLivesBilled)}
              endDate={getMonthEndDate()}
              billingDate={getMonthStartDate()}
              subsidiaryRevenue={subsidiaryRevenue}
            />
          )}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : (
            <PricePerUser value={price} />
          )}
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : customer ? (
            <ChangePaymentMethod paymentMethod={paymentMethod} />
          ) : (
            <></>
          )}
        </Grid>

        {contractStatus == "ACTIVE" && !!subsidiaryCompanies?.length && (
          <Grid item md={4}>
            {loading ? (
              <Skeleton width={"100%"} height="180px" />
            ) : (
              <SubsidiaryManager setOpenModal={setOpenSubsidiaryModal} />
            )}
          </Grid>
        )}
      </Grid>

      <Grid item md={12} style={{ marginTop: "40px" }}>
        {loading ? <Skeleton width={"100%"} height="100%" /> : <Bills />}
      </Grid>

      <SubsidiaryModal
        setOpen={setOpenSubsidiaryModal}
        open={openSubsidiaryModal}
        subsidiaryCompanies={subsidiaryCompanies}
        setSelectedSubsidiaryCompanies={setSelectedSubsidiaryCompanies}
        selectedSubsidiaryCompanies={selectedSubsidiaryCompanies}
        setTermsChecked={setAcceptSubsidiaryTerms}
        termsChecked={acceptSubsidiaryTerms}
        companyName={selectedCompany?.name}
        handleCreateSubscription={() => handleCreateSubscription()}
        loading={createSubsidiaryContracts?.isLoading}
        isEnabledOffers={isEnabledOffers}
      />
    </>
  );
};

export { BillingPagePeople };
