import styled from "styled-components";
import {
  IconButton,
  Icons,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      border-radius: 16px;
    }
    .modal-content-area {
      padding: 44px 80px;
    }
  }
`;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  text-align: center;
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledIcon = styled(Icons)``;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const StyledIconContainer = styled.div`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
`;

export {
  StyledModal,
  StyledSubtitle,
  StyledText,
  StyledIcon,
  StyledContainer,
  StyledIconButton,
  StyledIconContainer,
};
